<template>
  <section>
    <div class="searchwrap">
      <div class="searchBar">
         <div class="box-ui-select searchbar-content">
           <div class="title"><span>{{ $t('searchArea.applyDate') }}</span></div>
           <date-selector :class="'searchbar-content'" @setStartDate="setStartDate" @setEndDate="setEndDate" :startDefault="startDefault" :endDefault="endDefault" :configs="dateConfigs" />
         </div>
      </div>
      <div class="searchBar">
         <div class="box-ui-select searchbar-content">
           <div class="title"><span>{{ $t('searchArea.id') }}</span></div>
           <div>
             <input type="text" class="mr-5" v-model="reqData.adminId" :placeholder="$t('searchArea.id')" />
            <button class="btn-search" type="button" @click="pageSeach">
              <i class="fas fa-search"></i>
            </button>
           </div>
         </div>
      </div>
    </div>
    <div class="main-contents-wrapper">
      <div class="tab-wrap">
        <button type="button" class="tab-item" :class="{ active: currentStatus === '0' }" @click="changeListType" value="0">{{ $t('button.exchApply') }}</button>
        <button type="button" class="tab-item" :class="{ active: currentStatus === '1' }" @click="changeListType" value="1">{{ $t('button.exchComplete') }}</button>
      </div>
      <div class="table-wrapper">
        <table class="mainTable">
          <table-head :headInfo="headInfo" />
          <tbody v-if="applyList.length !== 0">
            <tr v-for="(item, idx) in applyList" :key="idx">
              <td>
                <button type="button" class="fc-id btn-link" @click="detailOpen('company', item.regId)">{{ item.regId }}</button>
              </td>
              <td>
                <span class="money-apply">{{ numberWithCommas(item.preCreditAmt) }}</span>
              </td>
              <td>
                <span class="money-apply">{{ numberWithCommas(item.creditAmt) }}</span>
              </td>
              <td style="width: 15%" v-if="tabStatus!=='1'">
                {{ numberWithCommas(Number(item.preCreditAmt) + Number(item.creditAmt)) }}
              </td>
              <td style="width: 15%"  v-if="tabStatus==='1'">
                {{ numberWithCommas(Number(item.preCreditAmt) + Number(item.realCreditAmt)) }}
              </td>
              <td>{{ Number(item.creditRate).toFixed(2) }}%</td>
              <td v-if="tabStatus==='1'">{{ numberWithCommas(Math.abs(Number(item.creditAmt) * (Number(item.creditRate) * 0.01)).toFixed(0)) }}</td>
              <td v-if="tabStatus!=='1'">{{ numberWithCommas(Math.abs(Number(item.realCreditAmt) * (Number(item.creditRate) * 0.01)).toFixed(0)) }}</td>
              <td style="width: 15%">
                {{ item.regDate }}
              </td>
              <td style="width: 15%" v-if="tabStatus === '1'">
                <span>{{ item.updDate }}</span>
              </td>
              <td class="statusColor">{{ item.statusText }}</td>
              <td style="width: 8%" v-if="tabStatus!=='1'">
                <div class="status-change-btn-wrap" v-if="Number(item.creditStatus) === 0">
                  <!-- <button class="btn-innerTable btn-status-change wait" type="button">{{ $t('common.wait') }}</button> -->
                  <button class="btn-innerTable btn-status-change refuse" type="button" @click="statusChange(item.creditIdx, item.creditAmt, item.creditType, item.creditStatus)">{{ $t('button.approval') }}</button>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="9">
                <span class="notice-tableDataEmpty">{{ $t('txt.noData') }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <pagination v-if="applyList.length !== 0" @goPage="setTableData" :pageNum="pageInfo.page" :pageSize="pageInfo.count_per_list" :totalCount="pageInfo.tatal_list_count" />
  </section>
</template>

<script>
import SearchFilter from "@/components/common/SearchFilter";
import DateSelector from "@/components/common/DateSelector";
import TableHead from "@/components/main/table/Head.vue";
import { creditUpdateStatus } from "@/api/member.js";
import { creditBotList } from "@/api/company.js";
import { getDateStr } from "@/libs/utils.js";
import Pagination from "@/components/common/Pagination";
import UiCheck from "@/components/ui/UiCheckSet";

export default {
  name: "CreditExchange",
  components: {
    TableHead,
    DateSelector,
    SearchFilter,
    Pagination,
    UiCheck
  },
  props: {
    status: {
      type: String,
      default: "0"
    }
  },
  watch: {
    $route: {
      async handler() {

      }
    },
    tabStatus:{
      handler(value){
        if(value==='1'){
          this.headInfo.dataList = this.postDataList
        }else{
          this.headInfo.dataList = this.preDataList
        }
      }
    }
  },
  data: function () {
    return {
      tableName: "Basic",
      headInfo: {
        fstColumn: false,
        dataList: ["companyId", "prePT", "ptExcahngeAmt", "postPT", "feeRate", "ptRealMoney", "applyDate", "status","option"]
      },
      postDataList: ["companyId", "applyPT","prePT", "ptExcahngeAmt", "feeRate", "ptRealMoney", "postPT", "applyDate", "updDate"],
      preDataList: ["companyId", "prePT", "ptExcahngeAmt", "postPT", "feeRate", "ptRealMoney", "applyDate", "status","option"],
      srchFiltersProp: {
        selectOptions: [
          {
            useAll: true,
            opts: [
              {
                title: "id"
              }
            ]
          }
        ]
      },
      reqData: {
        startDate: "",
        endDate: "",
        creditStatus: "0",
        page: 1,
        count_per_list: "30",
        adminId: "",
        creditType: "-1"
      },
      dateConfigs: {
        enableTime: true,
        dateFormat: "Y-m-d H:i"
      },
      startDefault: "",
      endDefault: "",
      pageInfo: {},
      applyList: [],
      checkList: [
        {
          val: "0",
          text: "요청"
        },
        {
          val: "1",
          text: "대기"
        },
        {
          val: "2",
          text: "완료"
        }
      ],
      tabStatus:"0",
      currentStatus: ''
    };
  },
  methods: {
    openWindow() {
      window.open("/user/userDetail", "userDetail", "top=0,left=150,scrollbars=no,resizable=no,width=1900,height=885,status=no");
    },
    async getCreditApplyList(pageNum) {
      if (pageNum) {
        this.reqData.page = pageNum;
      }
      this.reqData.creditStatus = this.currentStatus
      const data = this.reqData;
      return await creditBotList(data);
    },
    async changeListType(evt) {
      const target = evt.target;
      let chooseType = target.value;
      this.currentStatus = chooseType
      this.tabStatus = chooseType;
      if (chooseType === '') {
        chooseType = 'all'
      }
      this.$router.replace({params: {}, query:{status: chooseType}})

    },
    async setTableData(pageNum, status) {
      if (status) {
        this.currentStatus = status
      }
      const res = await this.getCreditApplyList(pageNum);
      const applyList = res.data.list;
      const pageInfo = res.data.pageInfo;
      if (pageInfo) {
        this.pageInfo = pageInfo;
      }
      this.applyList = this.dataConvertor(applyList);
    },
    creditPayment(dataObj) {
      const _creditAmt = prompt(`알 지급 금액`, dataObj.creditAmt);
      const trigger = {
        msg: "",
        prog: false
      };
      if (isNaN(_creditAmt)) {
        trigger.msg = "숫자만 입력해주세요.";
      } else if (_creditAmt <= 0) {
        trigger.msg = "지급 최소 금액은 0 이상 입니다. 다시 입력해주세요.";
      } else if (_creditAmt == null || _creditAmt == undefined) {
        trigger.msg = "";
      } else {
        trigger.msg = _creditAmt;
        trigger.prog = confirm(`${_creditAmt}을 지급합니다.`);
      }

      return trigger;
    },
    async statusChange(idx, creditAmt, type, status) {
      let reqData = {
        creditIdx: idx,
        creditType: type,
        creditStatus: status,
        creditAmt: creditAmt
      };
      switch (status) {
        case "0":
          reqData.creditStatus = "1";
          break;
      }
      const res = await creditUpdateStatus(reqData);
      this.setTableData();
      if (res.resultCode === "0") {
        if (reqData.creditStatus === "1") {
          alert("알 환전 완료");
        } else {
          alert("알 상태 변경 완료");
        }
      } else {
        if (reqData.creditStatus === "1") {
          alert("알 환전에 실패 했습니다. 다시 시도해주세요.");
        } else {
          alert("알 상태 변경에 실패 했습니다. 다시 시도해주세요.");
        }
      }
    },
    dataConvertor(dataList) {
      const _dataList = dataList;
      if (!dataList.length || dataList.length === 0) {
        return _dataList;
      }
      _dataList.forEach(items => {
        const creditStatus = items.creditStatus;
        let statusText = "";
        // - 0:요청, 1:완료, 2:대기 , -1"거부
        if (creditStatus === "0") {
          statusText = "대기중";
          items.statusClass = "apply";
        } else if (creditStatus === "1") {
          statusText = "환전완료";
          items.statusClass = "approve";
        }
        if (!items.realCreditAmt) {
          items.realCreditAmt = "-";
        }
        items.statusText = statusText;
        items.regDate = items.regDate.replace("T", " ");
        items.updDate = items.updDate.replace("T", " ");
        for (const item in items) {
          if (items[item] === "") {
            items[item] = "-";
          }
        }
      });
      return _dataList;
    },
    setStartDate(date) {
      // console.log(date);
      let _date = "";
      if (!date) {
        _date = new Date();
        _date.setHours(0, 0, 0);
      } else {
        _date = date[0];
      }
      this.startDefault = _date;
      this.reqData.startDate = getDateStr(_date, "yyyy-MM-dd HH:mm:ss");
    },
    setEndDate(date) {
      // console.log(date[0]);
      let _date = "";
      if (!date) {
        _date = new Date();
        _date.setHours(23, 59, 59);
      } else {
        _date = date[0];
      }
      this.endDefault = _date;
      this.reqData.endDate = getDateStr(_date, "yyyy-MM-dd HH:mm:ss");
    }
  },
  async created() {
    this.currentStatus = this.$route.query.status || this.status
    if (this.currentStatus === 'all') {
      this.currentStatus = ''
    }
    this.setStartDate();
    this.setEndDate();
    this.setTableData();
  }
};
</script>

<style scoped>
.statusColor {
  font-weight: 700;
}
.statusColor.apply {
  color: #ef8c1d;
}
.statusColor.approve {
  color: blue;
}
.statusColor.refuse {
  color: red;
}
.statusColor.wait {
  color: green;
}
</style>
